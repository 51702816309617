import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { greekFlag, usFlag } from "../constants/Images";
import { Snackbar, Alert } from "@mui/material";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const [langChange, setLangChange] = useState("gr");
    const [toastMessage, setToastMessage] = useState("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [error, setError] = useState("");

    const [displayedFlag, setDisplayedFlag] = useState(() => {
        return langChange === "en" ? usFlag : greekFlag;
    });

    const changeLanguageAuth = (lang) => {
        let flagImage = lang === "en" ? usFlag : greekFlag;
        setLangChange(lang);
        setDisplayedFlag(flagImage);
        i18n.changeLanguage(lang);
    };

    /***** Showing Toast/Alert ******/
    const showToast = (message) => {
        setToastMessage(message);
        setIsToastOpen(true);
    };

    const handleToastClose = () => {
        setIsToastOpen(false);
    };

    const handleErrorClose = () => {
        setIsErrorOpen(false);
    };

    const isValidEmail = (value) => {
        const regx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regx.test(value);
    };

    return (
        <AuthContext.Provider
            value={{
                displayedFlag,
                changeLanguageAuth,
                showToast,
                setIsErrorOpen,
                isValidEmail,
                error, 
                setError,
                isErrorOpen,
            }}
        >
            {children}
            <Snackbar
                open={isToastOpen}
                autoHideDuration={3000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="success" onClose={handleToastClose}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isErrorOpen}
                autoHideDuration={3000}
                onClose={handleErrorClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="error" onClose={handleErrorClose}>
                    {toastMessage}
                </Alert>
            </Snackbar>
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
export default AuthProvider;