import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
const ComingSoon = lazy(() => import("../components/ComingSoon"));

function MainStack() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<ComingSoon />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  )
}

export default MainStack